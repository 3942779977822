<template>
  <page-layout>
    <div class="signup-page">
      <h1 class="page-title">
        {{ $t('editing.userSignup') }}
      </h1>
      <form
        @submit.prevent="submitForm"
      >
        <div class="signup-form">
          <div class="signup-input-block input-block">
            <div class="input valid">
              {{ baseInfo.email }}
            </div>
            <label class="placeholder">{{ $t('editing.username') }}</label>
          </div>
          <input-block
            v-validate="`required|max:${max}|`"
            :label="$t('editing.userTitle')"
            v-model="baseInfo.username"
            class="signup-input-block"
            name="userName"
            type="text"
          />
          <input-block
            v-validate="`required|min:8|max:${max}|requireOneNumeric`"
            ref="confirmPassword"
            :label="$t('editing.setLoginPassword')"
            v-model="baseInfo.password"
            class="signup-input-block"
            name="userPassword"
            type="password"
          />
          <input-block
            v-validate="`required|min:8|max:${max}|requireOneNumeric|confirmed:confirmPassword`"
            :label="$t('editing.confirmPasswordAgain')"
            v-model="userInfo.verifyPassword"
            class="signup-input-block"
            name="verifyPassword"
            type="password"
          />
        </div>
        <button
          :disabled="isProcessing"
          class="btn btn-default btn-submit"
        >
          {{ $t('button.confirmSignup') }}
        </button>
      </form>
    </div>
  </page-layout>
</template>
<script>
import { mailConfirm, signup, trialSignup } from '@/API/User'
import PageLayout from '@/components/layout/PageLayout'
import InputBlock from '@/components/InputBlock'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useRoute, useRouter } from '@/utils/composable/router'
import { useMapGetters } from '@/utils/composable/vuex'
import { useValidator } from '@/utils/composable/validator'
import { useI18n } from '@/utils/composable/i18n'
import { Message } from 'element-ui'

export default defineComponent({
  name: 'SignUp',
  components: {
    PageLayout,
    InputBlock
  },
  setup () {
    const baseInfo = ref({
      email: null,
      username: null,
      password: null,
      token: null
    })
    const userInfo = ref({
      accountId: null,
      accountRoleId: null,
      invitedByUserId: null,
      verifyPassword: null
    })
    const trialUserInfo = {
      timeZone: null,
      contactNumber: null,
      customerName: null
    }
    const isProcessing = ref(false)

    const { fieldCommonMaxLength: max } = useMapGetters('validation', ['fieldCommonMaxLength'])

    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    onMounted(() => {
      // retrieve email token for validation
      baseInfo.value.token = route.query.token
      if (!baseInfo.value.token) {
        router.push({ name: 'PageLogin' })
        Message({
          message: t('errorMessage.lackOfSignUpToken'),
          type: 'error',
          duration: 3 * 1000,
          showClose: true
        })
        return
      }
      confirmEmailToken(baseInfo.value.token)
    })

    function confirmEmailToken (emailToken) {
      mailConfirm({ emailToken })
        .then(res => {
          baseInfo.value.email = res.email

          userInfo.value.accountId = res.accountId
          userInfo.value.invitedByUserId = res.invitedByUserId
          userInfo.value.accountRoleId = res.accountRole
          // userInfo.value.groupId = res.mailData ? res.mailData.groupId : 1 // 暫定預設 1 為 default group

          trialUserInfo.timeZone = res.timeZone
          trialUserInfo.contactNumber = res.contactNumber
          trialUserInfo.customerName = res.customerName
        })
        .catch(() => {
          router.push({ name: 'PageLogin' })
        })
    }

    const validator = useValidator()
    function submitForm () {
      validator.validateAll().then(result => {
        if (!result) return
        isProcessing.value = true
        const { verifyPassword, ...userInfoData } = userInfo.value
        const info = userInfoData.accountId
          ? { ...baseInfo.value, ...userInfoData }
          : {
            email: baseInfo.value.email,
            password: baseInfo.value.password,
            userName: baseInfo.value.username,
            token: baseInfo.value.token,
            ...trialUserInfo
          }
        const register = userInfoData.accountId ? signup : trialSignup
        register(info)
          .then(() => {
            router.push({ name: 'PageLogin' })
            Message({
              message: t('message.signUpSuccessPleaseLogin'),
              type: 'success',
              duration: 3 * 1000,
              showClose: true
            })
          })
          .catch(() => { isProcessing.value = false })
      })
    }

    return {
      baseInfo,
      userInfo,
      isProcessing,
      max,
      submitForm
    }
  }
})
</script>
<style lang="scss" scoped>
.signup-page {
  width: 360px;
  margin: 0 auto;

  .page-title {
    margin-top: 0;
    margin-bottom: 32px;
  }

  .signup-form {
    text-align: left;
    background-color: var(--color-bg-5);
    padding: 28px;
    border-radius: 5px;

    .signup-input-block.input-block {
      & >>> .error-text {
        right: 0;
      }

      &:not(:last-child) {
        margin-bottom: 37px;

        &.has-error {
          margin-bottom: 55px;
        }
      }

      &:first-of-type {
        margin-top: 28px;
      }
    }
  }

  .btn-submit {
    width: 148px;
    height: 44px;
    margin-top: 16px;
    border: none;
    border-radius: 4px;
  }
}
</style>

<template>
  <div class="app-layout page-layout">
    <div class="wrapper">
      <img
        v-if="logoType === 'SyGPS'"
        src="@/assets/images/sygps.svg"
        alt="logo"
        class="page-logo-img"
      >
      <img
        v-else-if="logoType === 'ASE'"
        src="@/assets/images/ase-logo.png"
        alt="logo"
        class="page-logo-img"
      >
      <img
        v-else-if="logoType === 'Oracle-red'"
        src="@/assets/images/oracle-red.png"
        alt="logo"
        class="page-logo-img"
      >
      <img
        v-else-if="logoType === 'Oracle-white'"
        src="@/assets/images/oracle-white.png"
        alt="logo"
        class="page-logo-img"
      >
      <img
        v-else-if="logoType === 'kiwi-smart'"
        src="@/assets/images/kiwi-smart.png"
        alt="logo"
        class="page-logo-img fix-height"
      >
      <img
        v-else
        src="@/assets/images/logo-text.svg"
        alt="logo"
        class="page-logo-img"
      >
      <main class="main">
        <div class="bg" />
        <slot />
      </main>
      <div
        class="language-setting"
        @click="showChangeLanguageDialog"
      >
        <svg-icon icon-class="language" />
        <span>{{ $t('editing.languageSetting') }}</span>
      </div>
      <change-language-dialog
        v-show="isShowLanguage"
        @closeDialog="isShowLanguage = false"
        @submit="isShowLanguage = false"
      />
    </div>
  </div>
</template>
<script>
import AppHeader from './AppHeader'
import ChangeLanguageDialog from '@/components/dialog/ChangeLanguageDialog'

export default {
  name: 'PageLayout',
  components: {
    AppHeader,
    ChangeLanguageDialog
  },
  data () {
    return {
      isShowLanguage: false
    }
  },
  computed: {
    logoType () {
      return localStorage.getItem('currentLogo')
    }
  },
  methods: {
    showChangeLanguageDialog () {
      this.isShowLanguage = true
    }
  }
}
</script>
<style lang="scss" scoped>
.page-layout {
  .page-logo-img {
    margin: 60px 0 55px;
    width: 200px;

    &.fix-height {
      width: auto;
      height: 120px;
    }
  }

  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .main {
    flex: 1;
    text-align: center;
  }

  .language-setting {
    align-self: flex-start;
    cursor: pointer;
    padding: 30px 20px;
  }
}
</style>

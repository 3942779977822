// Ref: https://github.com/vuejs/composition-api/issues/630#issuecomment-759671387

import { getCurrentInstance } from '@vue/composition-api'

export function useRouter () {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')

  return vm.proxy.$router
}

export function useRoute () {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')

  return vm.proxy.$route
}
